@topbar-height: 33px;
@loading-info-width: 500px;
@loading-info-height: 125px;

#editor-topbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    height: @topbar-height;
    background: linear-gradient(to bottom, #eeeeee 0%,#cccccc 100%);
    z-index: 100;
    border-bottom:1px solid #999;
    box-sizing: border-box;

    .brand, .title {
        display:inline-block;
        font-size:16px;
        color: #333;
        line-height: @topbar-height;
        flex: auto;
    }

    .brand {
        font-weight:bold;
        .logo {
            vertical-align: bottom;
        }
    }

    .btn-group {
        margin:3px 2px;

        .btn {
            height: 100%;
            width: auto;
        }
    }

    @media (max-width: 540px) {
        .btn-group{
            .btn {
                padding: 2px;
                min-width: 50px;
                text-align: center;
                font-size: 1em;
            }

            .fa {
                margin: 0;
            }
            .label {
                display: none;
            }
        }
        .brand span {
            display: none;
        }
    }
}

.wrap-container {
    width: 100%;
    height: calc(100% - @topbar-height);

    .wrap-splitter {
        position: absolute;
        top: 0;
        left: 50%;
        height: 100%;
        width: 2px;
        opacity: 0;
        background: #ddd;
        cursor: e-resize;

        &:hover,
        &.active {
            opacity: 1;
        }
    }

    .wrap-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background: #333;
        opacity: 0.0;
        display: none;
        cursor: e-resize;

        &.active {
            display: block;
            opacity: 0.2;
        }

    }

    .wrap {
        height: 100%;
    }

    @media (max-width: 540px) {
        .wrap.full {
            width: 100% !important;
        }
        .wrap.hide {
            display: none;
        }

        .wrap-splitter {
            display: none;
        }
    }
}

#editor-full-wrapper {
    position: relative;
    float: left;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
}

#editor-wrapper {
    position: relative;
    float: left;
    width: 50%;
    border-right:1px solid #999;
}


#results-wrapper {
    position: relative;
    float: left;
    width: 50%;
    background-color:#fff;
    background-image:
      -moz-linear-gradient(45deg, #ddd 25%, transparent 25%),
      -moz-linear-gradient(-45deg, #ddd 25%, transparent 25%),
      -moz-linear-gradient(45deg, transparent 75%, #ddd 75%),
      -moz-linear-gradient(-45deg, transparent 75%, #ddd 75%);
    background-image:
      -webkit-gradient(linear, 0 100%, 100% 0, color-stop(.25, #ddd), color-stop(.25, transparent)),
      -webkit-gradient(linear, 0 0, 100% 100%, color-stop(.25, #ddd), color-stop(.25, transparent)),
      -webkit-gradient(linear, 0 100%, 100% 0, color-stop(.75, transparent), color-stop(.75, #ddd)),
      -webkit-gradient(linear, 0 0, 100% 100%, color-stop(.75, transparent), color-stop(.75, #ddd));
    -moz-background-size:20px 20px;
    background-size:20px 20px;
    -webkit-background-size:20px 21px; /* override value for shitty webkit */
    background-position:0 0, 10px 0, 10px -10px, 0px 10px;
}

#results-frame {
    margin: 0;
    padding: 0;
    border: none;
    width: 100%;
    height: 100%;
}

#editor-loading-info {
    color: #fff;
    background: #2c3e50;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ul {
        display: block;
        font-size: 2em;
        list-style: none;
        margin-left: -1em;
        width: auto;

        li.loading {
            .spinner(20px, 1px);

            &::before {
                margin-right: 8px;
            }

            > span {
                display: none;
            }
        }

        li.done > span {
            color: #0f0;
            font-size: 20px;
            margin-right: 8px;
        }
    }
}

.toggle-area {
    flex-basis: 100%;
    position: absolute;
    display: none;
    flex-direction: row-reverse;
    width: auto;
    height: auto;
    bottom: 20px;
    right: 4px;

    @import url("./toggle.less");

    span {
        color: #fdd;
        padding:6px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        font-size:15px;
    }


    @media (max-width: 540px) {
        display: flex;
    }
}
