@alert-height: 40px;
@alert-hide-top: -(@alert-height + 5px);
@alert-hide-opacity: 0;
@alert-show-top: 0;
@alert-show-opacity: 1;

@keyframes alert {
    0% { top: @alert-hide-top; opacity: @alert-hide-opacity; }
    100% { top: @alert-show-top; opacity: @alert-show-opacity; }
}

#alert {
    position: relative;
    z-index: 200;

    > span {
        background-image: linear-gradient(135deg, transparent,
            transparent 25%, hsla(0,0%,0%,.1) 25%,
            hsla(0,0%,0%,.1) 50%, transparent 50%,
            transparent 75%, hsla(0,0%,0%,.1) 75%,
            hsla(0,0%,0%,.1));
        background-size: (@alert-height / 2) (@alert-height / 2);
        display: block;
        font: bold 16px/@alert-height sans-serif;
        height: @alert-height;
        position: absolute;
        text-align: center;
        text-decoration: none;
        width: 100%;

        top: @alert-hide-top;
        opacity: @alert-hide-opacity;
        transition: 0.6s all;

        &.success {
            color: #f6f6f6;
            background-color: #3cc453;
        }

        &.info {
            color: #f6f6f6;
            background-color: #3c4ac4;
        }

        &.warning {
            color: #f6f6f6;
            background-color: #c4b03c;
        }

        &.error {
            color: #f6f6f6;
            background-color: #c4453c;
        }

        &.shown {
            top: @alert-show-top;
            opacity: @alert-show-opacity;
        }
    }
}
