@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.spinner(@size, @border-size) {
    &::before {
        content: '';
        display: inline-block;
        box-sizing: border-box;
        width: @size;
        height: @size;
        border-radius: 50%;
        border: @border-size solid #ccc;
        border-top-color: #07d;
        animation: spinner .6s linear infinite;
    }

    &.centered::before {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: unit(-(@size / 2), px);
        margin-left: unit(-(@size / 2), px);
    }
}

