@keyframes glow {
    0% {
        border-color: #c11953;
        box-shadow: 0 0 5px rgba(193,25,83,.2), inset 0 0 0 rgba(255,255,255,0.2);
    }
    100% {
        border-color: #8e113c;
        box-shadow: 0 0 20px rgba(142,17,60,.6), inset 0 0 0 1px rgba(255,255,255,0.8);
    }
}

.btn {
    font-family: 'Roboto', Helvetica, sans-serif;
    border: 1px solid #c11953;
    background-color: #e72266;
    color: #fdd;
    padding:6px 15px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-size:10px;
    cursor:pointer;
    border-radius: 2px;
    margin:0 2px;
    outline:none;
    transition:all 0.2s;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
    &:hover {
        color:#fff;
        box-shadow: 0 0 0 1px rgba(255,255,255,0.8) inset;
        border-color:#8e113c;
    }
    &:active {
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1) inset;
        background-color:#c71b56;
        // padding:7px 15px 5px;
    }
    &.glow {
        animation: glow 1s ease-out infinite alternate;
        color: #fff;
    }
    .fa, .far, .fas {
        margin-right:0.5em;
    }
    .loading {
        .spinner(1em, 1px);
        margin-left:0.5em;
    }
}
